.medataContainer {
  border: 1px solid white;
  border-radius: 35px;

  @media screen and (max-width: 767px){
    border: 0;
  }
}

.cardMetadata {
  background: #121627;
  border-radius: 10px;
  margin: 5px;
}