.buy_tokens_modal {
  .modal-header {
    padding: 0px;
    .container {
      align-items: center;
      background: #000;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      box-sizing: border-box;
      display: flex;
      flex-flow: row;
      grid-area: menu;
      height: 100%;
      justify-content: space-between;
      padding: 10px;
      width: 100%;
      .fiat-top-menu {
        background: url('../../../assets/img/secure.svg') 9px 50%/11px
          no-repeat;
        border: 1px solid #0f0;
        border-radius: 20px;
        color: #0f0;
        font-size: 10px;
        height: -moz-fit-content;
        height: fit-content;
        padding: 0px 0px 0px 28px;
        width: -moz-fit-content;
        width: fit-content;
      }
      .close {
        align-items: center;
        aspect-ratio: 1/1;
        background: unset;
        border: unset;
        color: white;
        cursor: pointer;
        display: flex;
        font-size: 30px;
        height: 100%;
        justify-content: center;
        transition: all 0.2s ease;
        width: auto;
      }
    }
  }
  .modal-body {
    padding: 0px;
  }
}
