@import './theme.scss';

.main {
    // background: linear-gradient(132.98deg, #112A48 0.58%, #000000 63.15%);
    background: $background-color;
    color: white;
    font-family: $font-poppins;
}

.header {
    background: $background-color;
    padding: 8px 0px !important;

    @media (max-width: 992px) {
        padding: 20px 0px 40px 0px !important;
    }
}

.navbar {
    width: 100vw;
    position: fixed;
    backdrop-filter: blur(10px);
    z-index: 1001;
}

.nav-menu-wrap {
    margin-top: 10px;
    justify-content: end;
}

.nav-menu-box .navbar-nav {
    align-items: center;
}

.navbar-toggler {
    color: white !important;
    border-color: white !important;
}

.custom-navbar-button {
    margin: 0rem 1rem;
    padding: 0.5rem 0;

    font-size: 1.05rem;
    font-weight: 300;
    text-align: center;
    text-decoration: none !important;
    color: white !important;

    transition: all 0.2s ease-in;
    cursor: pointer;
    height:38px;
    &:hover {
        color: $primary !important;
    }
}

.home {
    // background-image: url('../img/bg.png');
    background-size: cover;
    background-position: 50% -200px;
    background-blend-mode: color-dodge;
    background-color: $background-color;

    @media (max-width: 1680px) {
        background-position: 50% -130px;
    }

    @media (max-width: 991px) {
        background-position: 50% 0px;
    }
}

.home-section {
    // min-height: 100vh;

    padding-top: 180px;

    @include sm-down-device {
        padding-top: 150px;
    }
}

.home-title {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 80.0274px;
    text-align: center;
    line-height: 110%;
    background: linear-gradient(91.77deg, #DB00FF 9.74%, #00FFF0 96.44%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    @include md-down-device {
        font-size: 28px;
    }
}

.home-title-1 {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 80.0274px;
    line-height: 96.34%;

    text-align: center;

    color: #FFFFFF;

    @include md-down-device {
        font-size: 28px;
        margin-top: 10px;
    }
}

.home-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 110.5%;

    text-align: center;
    letter-spacing: 0.16em;

    @include md-down-device {
        font-size: 1rem;
        letter-spacing: 0rem;
    }
}

.home-section-2 {
    background-image: url('../img/ellipse.png');
    background-size: cover;
    background-position: 50% -50px;
    // filter: drop-shadow(0px 123.56px 193.98px #FF03B8);
    // transform: matrix(-1, 0, 0, 1, 0, 0);

    margin-top: 40px;
    padding-top: 90px;

    @include md-down-device {
        background-image: none !important;
    }
}

.home-section-3 {
    // background-image: url('../img/roadmap/bg.png');
    margin-top: 70px;
    text-align: center;

    .home-roadmap {
        margin-top: 10px;
    }
}

.home-roadmap-gradiant {
    position: relative;
    width: 296.93px;
    height: 259.01px;
    background: radial-gradient(82.63% 82.63% at 30.35% 35.53%, rgba(220, 1, 255, 0.6) 51.1%, rgba(1, 255, 240, 0.6) 100%);
    filter: blur(350px);
}

.home-token-distribution {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 56.2841px;
    line-height: 96.34%;
    color: #EDDFFF;

    @include md-down-device {
        font-size: 28px;
    }
}

.home-coin-logo {
    padding: 5px;
    backdrop-filter: blur(10px);
    border-radius: 500px;

    img {
        width: 250px;

        @include sm-down-device {
            width: 180px;
        }
    }
}

.token-info-box {
    border-radius: 24px;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.19);

    box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 1px, rgba(0, 0, 0, 0.02) 0px 4px 8px, rgba(0, 0, 0, 0.02) 0px 16px 24px, rgba(0, 0, 0, 0.02) 0px 24px 32px;
}

.token-distribution-li {
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.progress {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    height: 10px;
}

.roadmap-phase-card {
    border-radius: 24px;
    padding: 1.5rem;
    background-color: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.19);

    box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 1px, rgba(0, 0, 0, 0.02) 0px 4px 8px, rgba(0, 0, 0, 0.02) 0px 16px 24px, rgba(0, 0, 0, 0.02) 0px 24px 32px;

    display: flex;
    flex-direction: column;
    height: 100%;

    color: rgba(255, 255, 255, 0.6);
    text-align: center;

    .title {
        font-size: 1.2rem;
        margin-bottom: 10px;
    }

    &:hover {
        border-color: $primary;
    }
}

.functional-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    color: white;
    row-gap: 10px;
}

.auth-button {
    padding: 0.5rem 1rem;
    background: linear-gradient(90deg, #FFBC0A -5.61%, #FF8922 111.73%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.19);
    border-radius: 10px;
    color: black !important;
    transition: .3s all ease-in-out;
    font-weight: 600;

    &:hover {
        background: $primary;
        background-color: rgba(255, 255, 255, 0.02);
        color: white !important;
    }
}

.social-box {
    font-size: 2rem;
    color: white;

    &:hover {
        color: $primary;
    }
}


.dropdown-menu {
    animation: growDown 500ms ease-in-out forwards;
    transform-origin: top center;
    background: #ffffff1c;
    text-align: center;
    color: white;

    a {
        color: white;
        transition: .3s all ease-in-out;

        &:hover {
            background-color: none !important;
            color: black !important;
        }
    }
}

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(1);
    }
}

.dropdown-item:active {
    background-color: none !important;
}

.dropdown-toggle {
    color: white !important;
    text-align: center;
}


.custom-accordion {
    font-size: 1rem;

    gap: 10px;

    .accordion-item {
        margin-top: 10px;
        padding: 0.5rem 1rem;
        background-color: rgba(255, 255, 255, 0.02);
        border: 1px solid rgba(255, 255, 255, 0.19);
        border-radius: 10px;
    }

    .accordion-header {
        width: 100%;
        margin: 0rem;

        button {
            text-align: left;
        }
    }

    .accordion-button {
        width: 100%;
        padding: 0.5rem 0rem;
        color: white;
        box-shadow: none !important;
        border: none;
        font-size: 1.1rem;
        font-weight: 400;
        background: transparent;
        display: flex;
        align-items: center;
    }

    .accordion-body {
        margin-top: 10px;
        color: rgba(255, 255, 255, 0.6);
    }
}

.unlock {
    padding-top: 150px;

    @include sm-down-device {
        padding-top: 65px;
    }

    button, a {
        text-decoration: none;
        color: white;

        margin: 5px 0;
        padding-left: 35px;
        min-width: 300px;
        height: 42px;

        display: flex;
        justify-content: start;
        align-items: center;
        
        background-color: rgba(255, 255, 255, 0.02);
        border: 1px solid rgba(255, 255, 255, 0.05);
        border-radius: 10px;
        
        transition: .3s all ease-in-out;

        span {
            font-size: 18px;
            margin-left: 10px;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.05);
            border: inherit;
        }
    }
}

.unlock-title {
    font-size: 32px;
    font-weight: 600;

    @include sm-down-device {
        font-size: 28px;
    }
}

.whitelist {
    padding-top: 70px;

    @include sm-down-device {
        padding-top: 65px;
    }

    a {
        min-width: 225px;
    }
}

.whitelist-title {
    font-size: 32px;
    font-weight: 600;

    @include sm-down-device {
        font-size: 28px;
    }
}

.private-sale {
    padding-top: 70px;

    @include sm-down-device {
        padding-top: 65px;
    }
}

.countdown-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid rgba($color: $primary, $alpha: 0.5);
    border-radius: 10px;
    padding: 10px;
    width: 75px;
}

.form-check-input {
    cursor: pointer;
}

a.active {
    border-bottom: 1px solid #FFBC0A;
}

.home-launch-app {
    margin-top: 66px;
    cursor: pointer;
    width: 208px;
    height: 56.29px;
    z-index: 999;
}


.home-info {
    // background-image: url('../img/token-allocation/bg.png');
    background-size: contain;
    background-position: bottom;
}

.home-info div {
    margin-top: 50px;

    @include md-down-device {
        margin-top: 30px;
    }
}

.home-info .home-title {
    margin-top: 60px;

    @include md-down-device {
        margin-top: 40px;
    }
}

.home-info img {
    width: 100%;
}

.modal-content {
    background-color: $background-color;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
}

.modal-header,
.modal-footer {
    border: none;
    font-family: $font-poppins;
}

.roadmap {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        margin: 5px;
    }

    @media (max-width: 992px) {
        flex-direction: column;

        div {
            max-width: 280px;
        }
    }
}
