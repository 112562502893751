/* Bootstrap overrides
-------------------------------------------------- */

$body: #000;
$headings-font-weight: 300 !important;

$light: #fafafa;

$headings-font-weight: 300 !important;
$a: #1392ff;

$primary: #FFAA12;
$primary-font-color: #cccccc;
$card-section-background-color: white;
$overlay-color: #F4EADB;
$background-color: #06071A;
$gold: #DFBF60;

/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/reboot';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$font-poppins: 'Poppins', sans-serif;

.custom-classname.btn-primary {
  background-color: green;
}

/* Base
-------------------------------------------------- */
html {
  min-height: 100%;
  font-size: 14px;
}

// main {
//   background: $background-color;
// }

.wrapper {
  min-height: 100vh;
}

.opacity-6 {
  opacity: 0.6;
}

.dapp-icon {
  padding: 5px;
  border-radius: 50%;
  background-color: $light;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &.icon-medium {
    width: 80px;
    height: 80px;
  }
}

/* Navbar
-------------------------------------------------- */
.elrond-logo {
  width: 85px;
  height: 25px;
}

.dapp-name {
  line-height: 1;
  border-left: 1px solid $gray-400;
  padding-left: 3px;
  margin-left: 4px;
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  margin: 0 7px;

  button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $white;

    &:hover {
      opacity: 0.9;
    }
  }
}

.transactions {
  .transaction-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &.table {

    .transaction-hash .trim {
      @include media-breakpoint-down(xl) {
        max-width: none;
      }

      @include media-breakpoint-down(lg) {
        max-width: 26rem;
      }

      @include media-breakpoint-down(md) {
        max-width: 12rem;
      }

      @include media-breakpoint-down(sm) {
        max-width: 8rem;
      }
    }

    td,
    tr {
      padding: 0.65rem;
    }

    td {
      white-space: nowrap;
      vertical-align: middle;
    }

    tr:last-of-type {
      td {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

/* Actions
-------------------------------------------------- */

.not-allwed {
  cursor: not-allowed;
}

/* Loader, Transaction success/Fail
-------------------------------------------------- */
.page-state {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .bg-blue .lds-ellipsis div {
    background: rgba(255, 255, 255, 0.4);
  }

  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(19px, 0);
    }
  }
}


// xl-device=====
@mixin fifteen-down-device {
  @media (max-width: 1500px) {
    @content;
  }
}

@mixin xxl-down-device {
  @media (max-width: 1399px) {
    @content;
  }
}

@mixin xl-down-device {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin xl-device {
  @media (min-width: 1200px) and (max-width: 1399px) {
    @content;
  }
}

@mixin lg-device {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin lg-up-device {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin lg-down-device {
  @media (max-width: 991px) {
    @content;
  }
}

// md-device============
@mixin md-device {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin md-up-device {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin md-down-device {
  @media (max-width: 767px) {
    @content;
  }
}

// sm-device
@mixin sm-device {
  @media (min-width: 576px) and (max-width: 768px) {
    @content;
  }
}

@mixin sm-down-device {
  @media (max-width: 576px) {
    @content;
  }
}