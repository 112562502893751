.buyButton {
  width: 118px;
  height: 38px;
  flex-shrink: 0;
  //fill: #121626;
  stroke-width: 0.5px;
  //stroke: rgba(255, 255, 255, 0.1);
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: blur(30px);
  //background-image: url('../../../assets/img/backgrounds/buy_button_background.svg');
  background-color: #ff9d19;
  border-radius: 8px;
  border-color: transparent;
  cursor: pointer;
  align-items: center;
  margin-top: 12px;
  .text_title {
    font-family: "Inter", sans-serif;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #06071a;
    font-style: normal;
    line-height: normal;
  }
  .text_content {
    color: #535353;
    font-family: Inter;
    font-size: 4px;
    font-style: normal;
    font-weight: 400;
    line-height: 5px; /* 125% */
  }
}

.modal {
  .modal_content {
    align-self: center;
    height: 700px;
    width: 100%;
  }

  iframe {
    background: #000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    grid-area: iframe;
    height: 100%;
    width: 100%;
    pointer-events:all;
  }
}
